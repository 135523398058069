@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .Scanify-carousel {
    height: 89vw;
  }
  .Scanify-video-wrapper {
    left: 2%;
    top: 1%;
    width: 96%;
  }
  .Scanify-video {
    border-radius: 4vw;
  }
  .phone-screen {
    width: 42vw;
    border-radius: 5vw;
  }
  .showPrev,
  .showNext {
    width: 10vw;
  }
  .showPrev {
    left: 5%;
  }
  .showNext {
    right: 5%;
  }

  .dots {
    width: 22%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: -6vw;
    left: 40%;
  }
}
