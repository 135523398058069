:root {
  --home-cb: cubic-bezier(0.2, 0.6, 0.2, 1);
  --line-color: rgba(255, 255, 255, 0.3);
}

/* !----------------------------------------------------------------------------------------------- */

.Home-terminal-btn {
  position: absolute;
  right: 4vw;
  top: 3vw;
  cursor: pointer;
  z-index: 999;
  padding: 0 0.6vw 0.3vw 0.5vw;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  font-size: 1.5vw;
  color: white;
  background-color: transparent;

  transition: all 0.5s;
  /* animation: leftIn 0.8s var(--home-cb); */
}

.Home-terminal-btn:hover {
  animation: rock 0.5s var(--home-cb) alternate-reverse infinite;
  color: black;
  background-color: white;
}

/* !----------------------------------------------------------------------------------------------- */

.Home {
  /* position: relative; */
  width: 100vw;
  height: 100vh;
}

.Home-html {
  /* font-family: LaBelleAurore, cursive; */
  font-family: "Caveat", cursive;
  font-size: 20px;
  font-weight: 500;

  height: 96vh;
  margin: 2vh;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: scroll;
  z-index: 2;

  animation: rightIn 0.8s var(--home-cb);
}
.Home-html::-webkit-scrollbar {
  display: none;
}
.Home-html-line {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-left: 1px solid var(--line-color);
}

.Home-level {
  padding: 1vh 2vh;
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
}
.Home-level1 {
  padding: 1vh 2vh;
  display: flex;
  flex-direction: column;

  border-left: 1px solid var(--line-color);
}
.Home-body {
  margin-top: auto;
}

.Home-titles-wrapper {
  width: 50vw;
  padding: 1vh 2vh;

  border-left: 1px solid var(--line-color);
  z-index: 80;
}
.Home-titles {
  display: table;
  font-family: Semibold;
  position: relative;
  width: max-content;
  font-size: 8vw;
  line-height: 9vw;
  z-index: 90;
  overflow: hidden;

  padding-left: 2vw;
  transition: padding-left var(--home-cb) 0.4s;
}
.Home-titles:hover {
  padding: 0 5vw;
}
.Home-titles-word {
  position: relative;
  display: block;
  z-index: 100;
  cursor: pointer;

  animation: animate 0.5s var(--home-cb);
  animation-fill-mode: backwards;
}
.delay03 {
  animation-delay: 0.2s;
}

.delay06 {
  animation-delay: 0.3s;
}

.delay09 {
  animation-delay: 0.5s;
}

/* .Home-init-on {
  animation: rockBig 0.7s var(--cb) 4s alternate-reverse infinite;
}
.Home-init-off {
  animation: none;
} */

.techyTransition {
  font-size: 2.5vh;
  color: #575757;
}
/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 980px) and (max-height: 1500px) {
  :root {
    --line-color: rgba(255, 255, 255, 0.12);
  }
  .Home-html {
    overflow: visible;
  }
  .techyTransition {
    font-size: 28px;
    color: #888888;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 1500px) and (max-height: 980px) {
  :root {
    --line-color: rgba(255, 255, 255, 0.12);
  }
  .Home-titles {
    font-size: 8vw;
    line-height: 9vw;
  }
  .techyTransition {
    font-size: 2.5vh;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 870px) and (max-height: 1300px) {
  .Home-html {
    overflow: visible;
  }
  .techyTransition {
    font-size: 24px;
    color: #888888;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 1300px) and (max-height: 870px) {
  .Home-titles {
    font-size: 8vw;
    line-height: 9vw;
  }
  .techyTransition {
    font-size: 2.5vh;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 670px) and (max-height: 1024px) {
  .Home-html {
    overflow: visible;
  }
  .techyTransition {
    font-size: 19px;
    color: #888888;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 1024px) and (max-height: 670px) {
  .Home-titles {
    font-size: 8vw;
    line-height: 9vw;
  }
  .techyTransition {
    font-size: 16px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 540px) and (max-height: 800px) {
  .Home-html {
    overflow: visible;
  }
  .techyTransition {
    font-size: 16px;
    color: #888888;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 800px) and (max-height: 540px) {
  .Home-titles {
    font-size: 7vw;
    line-height: 8vw;
  }
  .techyTransition {
    font-size: 14px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 470px) and (max-height: 650px) {
  .Home-html {
    overflow: visible;
  }
  .techyTransition {
    font-size: 15px;
    color: #888888;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 650px) and (max-height: 470px) {
  .techyTransition {
    font-size: 12px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 350px) and (max-height: 500px) {
  .Home-html {
    overflow: visible;
  }
  .techyTransition {
    font-size: 12px;
    color: #888888;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 500px) and (max-height: 350px) {
  .techyTransition {
    font-size: 10px;
  }
}
