@keyframes yAxisLong {
  0% {
    transform: translateY(0px) scale(1) rotateZ(0deg);
  }
  50% {
    transform: translateY(-30vh) scale(1.5) rotateZ(25deg);
  }
  100% {
    transform: translateY(-60vh) scale(0) rotateZ(45deg);
  }
}

@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .Contact-title {
    font-size: 11vw;
    width: 61vw;
    margin: 20vw 0 0 10vw;
    word-break: break-all;
  }
  .Contact-block {
    width: 80vw;
  }
  .Contact-block-bigp {
    font-size: 3.2vw;
  }

  /* *-------------------------------------- */

  .Rocket {
    width: 8vw;
    animation: xAxis 2s ease-in;
  }
  .Rocket > img {
    animation: yAxisLong 2s linear;
  }

  /* *-------------------------------------- */
  .Contact-form {
    flex-direction: column;
    width: 80vw;
  }
  .Contact-form-input > div {
    font-size: 4vw;
    padding: 0;
  }
  .Contact-form-input > input,
  textarea {
    font-size: 4.2vw;
    width: 50vw;
  }
  .Contact-form-input-big > textarea {
    width: 80vw;
    height: 14vw;
  }
  .Contact-form-btn {
    width: 30vw;
    font-size: 4.5vw;
    padding: 2vw 5vw;
    border-radius: 1vw;
  }
  .Contact-form-btn > img {
    right: -7vw;
    width: 3vw;
  }

  /* *-------------------------------------- */
  .Contact-links-wrapper {
    margin: 6vw 0 10vw 0;
    width: 80vw;
  }
  .Contact-links {
    width: 12vw;
    height: 12vw;
    padding: 1.2vw;
    box-shadow: 0 2vw 3vw rgba(0, 0, 0, 0.3);
  }
  .Contact-links:hover {
    box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3);
  }
  .Contact-links .fab {
    border-radius: 50%;
    line-height: calc(12vw - 2.8vw);
    font-size: 5vw;
  }

  .Contact-links:nth-child(1) .fab {
    color: #0e76a8;
    box-shadow: 0 7px 10px #0e76a8;
  }
  .Contact-links:nth-child(2) .fab {
    color: #6e5494;
    box-shadow: 0 7px 10px #6e5494;
  }
  .Contact-links:nth-child(3) .fab {
    color: #00aced;
    box-shadow: 0 7px 10px #00aced;
  }
  .Contact-links:nth-child(4) .fab {
    color: #ea4335;
    box-shadow: 0 7px 10px #ea4335;
  }
  .Contact-links:nth-child(5) .fab {
    color: #e4405f;
    box-shadow: 0 7px 10px #e4405f;
  }
}
