.Estatery {
}

.Estatery-website {
  margin-left: 15vw;
  width: 85vw;
  height: 45vw;
}

.Estatery-carousel {
  position: relative;
  left: -15vw;
  width: 80vw;
}

.Estatery-desc {
  position: relative;
  margin: 20px;
  text-align: center;
}

.Estatery-screen {
  position: relative;
  margin: 0 auto;
  width: 50vw;
  height: 31.1vw;
  background-color: black;
}
.Estatery-video-wrapper {
  position: absolute;
  animation: fadeIn var(--cb) 0.6s;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Estatery-video {
  position: absolute;
  top: -0.3%;
  animation: fadeIn var(--cb) 0.6s;
  width: 100%;
}

@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
}
