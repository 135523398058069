@keyframes launch {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(47vw);
  }
}
@keyframes xAxis {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(47vw);
  }
}
@keyframes yAxis {
  0% {
    transform: translateY(0px) scale(1) rotateZ(0deg);
  }
  50% {
    transform: translateY(-14vw) scale(1.5) rotateZ(25deg);
  }
  100% {
    transform: translateY(-36vw) scale(0) rotateZ(45deg);
  }
}

/* !---------------------------------------------------------------------------------------------- */

.Contact {
  width: 100vw;
}
.Contact-title {
  font-size: 6vw;
  margin: 20vw 0 0 15vw;

  animation: shiftUp var(--cb) var(--shiftup-time);
  animation-fill-mode: backwards;
}
.Contact-block {
  width: 50vw;
  margin: 0 auto;
  padding-bottom: 10vw;

  animation: shiftUp var(--cb) var(--shiftup-time);
}
.Contact-block-bigp {
  font-family: Medium;
  font-size: 2vw;
}
/* !---------------------------------------------------------------------------------------------- */

.Rocket {
  position: absolute;
  bottom: 0;
  left: 15vw;
  width: 4vw;

  animation: xAxis 2s ease-in;
}
.Rocket > img {
  width: 100%;
  animation: yAxis 2s linear;
}

/* !---------------------------------------------------------------------------------------------- */

.Contact-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 50vw;

  justify-content: space-between;
  font-family: Regular;
}

.Contact-form-input {
  padding: 20px 0;
}
.Contact-form-input-big {
  width: 50vw;
}

.Contact-form-input > div {
  font-size: 1vw;
  padding: 10px 0;
}

.Contact-form-input > input,
textarea {
  font-size: 1.4vw;
  width: 22vw;

  border: none;
  border-bottom: 0.5px solid #ffffff;
  color: inherit;
  padding: 10px 0;
  background-color: transparent;
  outline: none;
  font-family: Regular;

  transition: all 0.5s;
}
.Contact-form-input > input:focus,
textarea:focus {
  /* font-size: 1.4vw; */
  border-bottom: 0.5px solid #ea0f1e;
}

.Contact-form-input-big > textarea {
  width: 50vw;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Contact-form-input-big > textarea::-webkit-scrollbar {
  display: none;
}

.Contact-form-btn {
  position: relative;
  bottom: 0;
  right: 0;

  text-align: center;
  font-size: 2.2vw;
  color: white;
  cursor: pointer;
  padding: 0.5vw 4vw;
  margin: 2vw 0;
  background: #0000;
  border: 1px solid rgba(256, 256, 256, 1);
  border-radius: 5px;
  transition: all cubic-bezier(0.2, 0.6, 0.2, 1) 1s;
}

.Contact-form-btn:hover {
  /* background: linear-gradient(#ea0f1e, #a80b15); */
  background-color: #ea0f1e;
  border: 1px solid #ea0f1e;
  color: #000;
  transform: translateX(8px);
}
.Contact-form-btn > img {
  position: absolute;
  right: -3vw;
  opacity: 0;
  width: 1.5vw;
  transition: all cubic-bezier(0.2, 0.6, 0.2, 1) 1s;
}
.Contact-form-btn:hover img {
  opacity: 1;
  animation: rock 0.7s alternate-reverse infinite;
}

/* !---------------------------------------------------------------------------------------------- */

.Contact-links-wrapper {
  margin: 2vw 0 6vw 0;
  width: 30vw;
  display: flex;
  justify-content: space-between;
}
.Contact-links {
  position: relative;
  width: 60px;
  height: 60px;
  display: block;
  text-align: center;
  border-radius: 50%;
  padding: 6px;
  box-sizing: border-box;
  text-decoration: none;
  background: linear-gradient(0deg, #bbb, #fff);

  transition: 0.5s;
}
/* .Contact-links:hover {
  text-decoration: none;
} */
.Contact-links .fab {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(0deg, #fff, #bbb);
  border-radius: 50%;
  line-height: calc(60px - 12px);
  font-size: 30px;
  color: #262626;
  transition: 0.5s;
}

.Contact-links:nth-child(1):hover .fab {
  color: #0e76a8;
  box-shadow: 0 7px 10px #0e76a8;
}
.Contact-links:nth-child(2):hover .fab {
  color: #6e5494;
  box-shadow: 0 7px 10px #6e5494;
}
.Contact-links:nth-child(3):hover .fab {
  color: #00aced;
  box-shadow: 0 7px 10px #00aced;
}
.Contact-links:nth-child(4):hover .fab {
  color: #ea4335;
  box-shadow: 0 7px 10px #ea4335;
}
.Contact-links:nth-child(5):hover .fab {
  color: #e4405f;
  box-shadow: 0 7px 10px #e4405f;
}
