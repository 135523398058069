@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .Work-wrapper {
    padding: 20vw 0;
  }
  .Work-title {
    font-size: 10vw;
    line-height: 12vw;
  }
  .Work-desc {
    font-size: 2.8vw;
  }

  /* *------------------------------------------ */

  .Work-file-subtitle {
    padding-left: 10vw;
    font-size: 2.8vw;
  }
  .Work-file-title {
    font-size: 10vw;
    line-height: 12vw;
    margin-left: 10vw;
  }

  .Work-file-h1 {
    font-size: 5.5vw;
    font-family: semibold;
    margin: 3vw 0;
  }
  /* *Check */
  .Work-file-h2 {
    font-size: 3vw;
    font-family: semibold;
    margin: 1.5vw 0;
  }
  .Work-file-p {
    margin: 3vw 0;
  }

  /* *---------------------------------------- */

  .Work-file-img-wrapper {
    margin-left: 10vw;
    width: 90vw;
    min-height: 29vw;
  }

  /* !cehck */
  .wrapper2 {
    left: 0vw;
    width: 20vw;
    min-height: 30vw;
    border-radius: 3vw;
  }

  /* *---------------------------------------- */
  .Work-file-content-big {
    font-size: 1.2em;
    line-height: 1.2em;
  }
  /* *---------------------------------------- */

  .Work-file-vid-wrapper {
    left: 0;
    width: 90vw;
    height: 50vw;
  }

  /* *---------------------------------------- */

  .Work-file-billboard {
    left: 0vw;
    width: 84vw;
  }
  .Work-file-billboard-img {
    height: 10vw;
  }
}
