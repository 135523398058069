@keyframes backFade {
  0% {
    background-color: #000;
  }
  100% {
    background-color: #eee;
  }
}

.Work {
  width: 100vw;
}

.Work-wrapper {
  padding: 15vw 0;
}

.Work-project-wrapper {
  margin: 5vw 15vw;
  padding-left: 0;
  transition: padding-left var(--cb) 0.5s;
}

.Work-project-wrapper:hover {
  padding-left: 3vw;
}

.Work-title {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.Work-desc {
  font-family: Regular;
  font-size: 1.5vw;
  user-select: none;

  animation: shiftUp var(--cb) var(--shiftup-time),
    fadeIn var(--cb) var(--shiftup-time);
}

/* !------------------------------------------------------------------------------------------------ */

.Work-file *::selection {
  background: #ccc;
}

.Work-file {
  width: 100vw;
  background-color: #eee;
  animation: backFade ease-in-out 1s;
}

.Work-file-title-wrapper {
  margin: 10vw 0 17vw 0;
}

.Work-file-subtitle {
  padding-left: 15vw;
  font-size: 1.5vw;
  color: #555555;

  /* animation: shiftUp var(--cb) var(--shiftup-time); */
}

.Work-file-title {
  font-size: 7vw;
  line-height: 9vw;
  margin-left: 15vw;
}

.Work-file-h1 {
  font-size: 3vw;
  font-family: semibold;
  margin: 3vw 0;
}

.Work-file-h2 {
  font-size: 1.7vw;
  font-family: semibold;
  margin: 1.5vw 0;
}

.Work-file-p {
  margin: 3vw 0;
}

/* !----------------------------------------------------------------------------------------------- */

.Work-file-img-wrapper {
  position: relative;
  left: -10vw;
  width: 70vw;
  min-height: 20vw;
  box-shadow: 0 0 0 1px #000000 inset;
}
.wrapper2 {
  left: 0vw;
  width: 20vw;
  min-height: 30vw;

  border-radius: 3vw;
}

/* Iphone frame */
.Work-file-img-frame {
  position: absolute;
  left: -3%;
  width: 106%;
  z-index: 900;
}

.Work-file-loading {
  position: absolute;
  top: 50%;
  left: 35%;
  width: 30%;
}
.Work-file-img {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 0 3vw 1px #bebebe;
  z-index: 100;
  margin-bottom: -10px;
  animation: fadeIn var(--cb) 0.6s;
}
.Work-file-img-dark {
  box-shadow: 0 0 3vw 2px #4e4e4e;
}

/* !----------------------------------------------------------------------------------------------- */

.Work-file-content-big {
  font-size: 1.6vw;
  line-height: 2.3vw;
}
.Work-file-content {
}
.Work-file-content-block {
  margin: 15vw auto;
}

/* !----------------------------------------------------------------------------------------------- */

.Work-file-vid-wrapper {
  position: relative;
  left: -10vw;
  width: 85vw;
  height: 47vw;
}
.Work-file-vid {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 0 3vw 2px #4e4e4e;
}

/* !----------------------------------------------------------------------------------------------- */

.Work-file-billboard {
  position: relative;
  left: -10vw;
  width: 64vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 3vw;
  margin: 4vw 0;
  background-color: #fff;
  box-shadow: 0 0 3vw 1px #bebebe;
}
.Work-file-billboard-img {
  height: 8vw;
  transition: transform var(--cb) 0.5s;
}
.Work-file-billboard-img:hover {
  transform: scale(1.3);
}
.Work-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #bd0000;
  height: 5px;
  z-index: 1000;
}

.Work-file-underCons {
  display: inline;
}

.github {
  display: block;
  margin: 5vw 0;
}
