@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.tag-list {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;

  width: 100%;
}

.outer {
  width: 98%;
  overflow: hidden;
}

.inner {
  display: flex;
  width: fit-content;
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 25s;
}

.tag {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 1.3rem;
  border-radius: 0.4rem;
  padding: 0 1rem;
  margin-right: 1rem;

  border: 1px solid rgba(255, 255, 255, 0.5);
}

.tag > span {
  font-size: 1.5rem;
}

.fade {
  pointer-events: none;
  background: linear-gradient(
    90deg,
    #000,
    transparent 20%,
    transparent 80%,
    #000
  );
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .tag {
    font-size: 0.9rem;
    padding: 0 0.5rem;
    margin-right: 0.5rem;
  }

  .tag > span {
    font-size: 1rem;
  }
}
