.Avatar {
  position: fixed;
  top: 10vh;
  right: 0;
  width: 90vh;
  height: 90vh;
}

.Avatar-p {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 22.5px;
  line-height: 15px;
  letter-spacing: -2px;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  line-break: normal;

  text-transform: uppercase;
  font-family: Semibold;

  background-image: url(../Media/mine/avatar2.jpeg);
  background-size: 90vh;
  background-repeat: no-repeat;
  z-index: 1;

  transition: all 1s;

  animation: trans cubic-bezier(0.2, 0.6, 0.2, 1) 1.2s;
  animation-delay: 0.1s;
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 980px) and (max-height: 1500px) {
  .Avatar {
    right: -22vh;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2-big.jpeg);
    background-size: 80vh;
    font-size: 20.7px;
    line-height: 14px;
    letter-spacing: -2px;
  }
}

/* *------------------------------------------ */

@media only screen and (max-height: 980px) {
  .Avatar {
    right: 0vw;
    top: 10vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2.jpeg);
    background-size: 90vh;
    font-size: 14.5px;
    line-height: 9px;
    letter-spacing: -0.9px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 870px) and (max-height: 1300px) {
  .Avatar {
    right: -22vh;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2-big.jpeg);
    background-size: 80vh;
    font-size: 17px;
    line-height: 12px;
    letter-spacing: -1px;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 1300px) and (max-height: 870px) {
  .Avatar {
    right: 0vw;
    top: 10vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2.jpeg);
    background-size: 90vh;
    font-size: 13px;
    line-height: 8px;
    letter-spacing: -0.9px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 670px) and (max-height: 1024px) {
  .Avatar {
    right: -22vh;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2-big.jpeg);
    background-size: 80vh;
    font-size: 14px;
    line-height: 9px;
    letter-spacing: -1px;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 1024px) and (max-height: 670px) {
  .Avatar {
    right: 0vw;
    top: 10vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2.jpeg);
    background-size: 90vh;
    font-size: 10.4px;
    line-height: 6px;
    letter-spacing: -0.8px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 540px) and (max-height: 800px) {
  .Avatar {
    right: -22vh;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2-big.jpeg);
    background-size: 80vh;
    font-size: 11px;
    line-height: 7.1px;
    letter-spacing: -0.7px;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 800px) and (max-height: 540px) {
  .Avatar {
    right: -5vw;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2.jpeg);
    background-size: 80vh;
    font-size: 7.6px;
    line-height: 5px;
    letter-spacing: -0.7px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 470px) and (max-height: 650px) {
  .Avatar {
    right: -22vh;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2-big.jpeg);
    background-size: 80vh;
    font-size: 8.8px;
    line-height: 5.8px;
    letter-spacing: -0.6px;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 650px) and (max-height: 470px) {
  .Avatar {
    right: -5vw;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2.jpeg);
    background-size: 80vh;
    font-size: 6.8px;
    line-height: 4px;
    letter-spacing: -0.6px;
  }
}

/* !---------------------------------------------------------------------------------------- */

@media only screen and (max-width: 350px) and (max-height: 500px) {
  .Avatar {
    right: -30vw;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2-big.jpeg);
    background-size: 80vh;
    font-size: 7.5px;
    line-height: 4px;
    letter-spacing: -0.5px;
  }
}

/* *------------------------------------------ */

@media only screen and (max-width: 500px) and (max-height: 350px) {
  .Avatar {
    right: -5vw;
    top: 20vh;
  }
  .Avatar-p {
    background-image: url(../Media/mine/avatar2.jpeg);
    background-size: 80vh;
    font-size: 5px;
    line-height: 3px;
    letter-spacing: -0.5px;
  }
}
