.Netflix {
}

@keyframes boxshadow {
  0% {
    box-shadow: 0 0 0vw 0px #bebebe;
  }
  100% {
    box-shadow: 0 0 3vw 1px #bebebe;
  }
}

.Netflix-website-shadow {
  margin-left: 15vw;
  width: 85vw;
  height: 47vw;

  box-shadow: 0 0 2vw 2px #c3c3c3;
  animation: boxshadow ease-in-out 4s;
}

@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .Netflix-website-shadow {
    display: none;
  }
}
