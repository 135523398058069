/* !Color Palette---------------------------------------------------------------------------- */
/* 
rgb(0, 0, 0) #000000
rgb(30, 30, 30) #1e1e1e
rgb(190,190 ,190) #bebebe
rgb(205, 205, 205) #cdcdcd
rgb(238, 238, 238) #eeeeee

rgb(189, 0, 0) #bd0000

#ea0f1e 
*/

@font-face {
  font-family: Semibold;
  src: url(./Media/Semibold.woff) format("woff");
}
@font-face {
  font-family: Regular;
  src: url(./Media/Regular.woff) format("woff");
}
@font-face {
  font-family: Medium;
  src: url(./Media/Medium.woff) format("woff");
}
@font-face {
  font-family: LaBelleAurore;
  src: url(./Media/LaBelleAurore.woff) format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Dawning+of+a+New+Day&display=swap");

@font-face {
  font-family: SourceCode, monospace;
  src: url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap");
}

/* !------------------------------------------------------------------------------------------------ */

@keyframes trans {
  0% {
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes shiftUp {
  from {
    transform: translateY(10vw);
  }
  to {
    transform: translateY(0vw);
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(30vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes rightIn {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes leftIn {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rock {
  0% {
    transform: translateX(2vw);
  }
  100% {
    transform: translateX(0px);
  }
}
/* @keyframes rockBig {
  0% {
    padding-left: 4vw;
  }
  100% {
    padding-left: 0;
  }
} */

/* !------------------------------------------------------------------------------------------------ */

:root {
  --cb: cubic-bezier(0.2, 0.6, 0.2, 1);
  --shiftup-time: 0.7s;
}

::selection {
  background: #333;
}

/* !------------------------------------------------------------------------------------------------ */

.App {
  /* Text style */
  font-family: Semibold;
  color: rgb(205, 205, 205);
  background-color: rgb(0, 0, 0);
  display: flex;

  /* App layout */
  width: 100vw;
  overflow: hidden;
}

.red {
  color: #ea0f1e;
  /* rgb(189, 0, 0); */
}

.title {
  font-size: 7vw;
  line-height: 9vw;
  margin-left: 15vw;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  animation: shiftUp var(--cb) var(--shiftup-time);
}
.content-block {
  font-family: Regular;
  font-size: 1.3vw;
  width: 50vw;
  margin: 20vw auto;
  line-height: 2vw;
}
.title-dark {
  color: #1e1e1e;
}
.content-block-dark {
  color: #1e1e1e;
}

.quote-wrapper {
  border-left: solid 0.3vw #bebebe;
  padding: 1vw 2vw;
}
.quote {
  font-size: larger;
}
.quote-mark {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 2.1vw;
}
.quoter {
  font-weight: bold;
}

/* !------------------------------------------------------------------------------------------------ */

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translateX(-10vw) rotate3d(1, 5, 0, 40deg);
    transform-origin: 100% 0%;
    /* box-shadow: -20px 0 30px -2px black; */
  }
  to {
    opacity: 1;
    transform: translateX(0) rotate3d(0, 0, 0, 0);
    transform-origin: 100% 0%;
    /* box-shadow: 0 0 0 0 black; */
  }
}

.int-obv {
  /* transition: opacity var(--cb) 1.5s; */
  opacity: 0;
  animation: shiftUp var(--cb) var(--shiftup-time);
}
.block-disappear {
  opacity: 0;
  animation: fadeOut ease-out 0.5s, shiftUp var(--cb) var(--shiftup-time);
}
.block-appear {
  opacity: 1;
  animation: fadeIn ease-out 0.5s, shiftUp var(--cb) var(--shiftup-time);
}
.block-roll {
  opacity: 1;
  animation: rollIn ease-out 0.5s, shiftUp var(--cb) var(--shiftup-time);
}

/* !------------------------------------------------------------------------------------------------ */

.Inline-link {
  color: inherit;
}
.Link {
  text-decoration: none;
}
.Link > span {
  cursor: pointer;
  padding: 5px 0;
  transition: all 0.5s;
}
.Link > span > img {
  width: 1vw;
  position: relative;
  top: 2px;
  left: 10px;
}
.Link > span:hover {
  text-decoration: underline 3px;
}
.Link > span:hover img {
  animation: rock 0.7s var(--cb) alternate-reverse infinite;
}

/* !------------------------------------------------------------------------------------------------ */

.Copyright {
  color: rgb(205, 205, 205);
  text-align: center;
  margin: 1vw;
  font-family: Medium;
  font-size: 1.2vw;

  animation: shiftUp var(--cb) var(--shiftup-time),
    fadeIn var(--cb) var(--shiftup-time);
}
.Dark {
  color: #000;
}

/* !------------------------------------------------------------------------------------------------ */

@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .quote-mark {
    font-size: 5vw;
  }

  .Link > span > img {
    width: 2vw;
    top: 0px;
    left: 5px;
  }

  .content-block {
    font-family: Regular;
    font-size: 3.5vw;
    width: 80vw;
    margin: 20vw 10vw;
    line-height: 5vw;
    /* text-align: justify; */

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    word-wrap: break-word;
  }

  .Copyright {
    font-size: 3vw;
  }
  .Copyright-works {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 5vw;
  }
  .Copyright-contact {
    position: relative;
    bottom: 0;
  }

  /* *------------------------------------------ */
}
