.Scanify-carousel {
  position: relative;
  height: 42vw;
}
.Scanify-video-wrapper {
  position: absolute;
  left: 2%;
  top: 1.5%;
  width: 95%;
  margin-bottom: -10px;
  animation: fadeIn var(--cb) 0.6s;
  z-index: 100;
}
.Scanify-video {
  animation: fadeIn var(--cb) 0.6s;
  border-radius: 2vw;
  width: 100%;
}
.phone-screen {
  position: relative;
  margin: 0 auto;
  width: 20vw;
  min-height: 30vw;
  border-radius: 3vw;
  height: 100%;
  background-color: black;
}
.showPrev,
.showNext {
  width: 6vw;
  position: absolute;
  cursor: pointer;
  transition: all 0.6s;
  top: 50%;
}
.showPrev:hover,
.showNext:hover {
  transform: scale(1.3);
}
.showPrev > img,
.showNext > img {
  width: 100%;
}
.showPrev {
  left: 10%;
}
.showNext {
  right: 10%;
}

.dots {
  width: 12%;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: -6vw;
  left: 44%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
}

.dot-active {
  background-color: red;
}
