@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .About-header {
    font-size: 8vw;
    line-height: 10vw;
    width: 90vw;
    margin: 20vw 0 30vw 10vw;
  }
  /* *------------------------------------------ */
  .dropdown-content-wrapper {
    top: 8vw;
  }
  .dropdown-content {
    top: 3vw;
    left: -24vw;
    font-size: 2.3vw;
    line-height: 2.4vw;
    width: 60vw;
    padding: 2vw 2vw 0 2vw;
    border-radius: 8px;
  }
  .dropdown-content > img {
    top: -1.4vw;
    left: 30vw;
    height: 1.4vw;
  }
  /* *------------------------------------------ */
  .About-backdrop-wrapper {
    margin-left: 0;
    width: 100vw;
    height: 100vw;
  }
  /* *------------------------------------------ */
  .About-heading {
    font-size: 6vw;
  }
}
