.About-wrapper {
  animation: shiftUp var(--cb) var(--shiftup-time);
}

.About-header {
  font-size: 7vw;
  line-height: 9vw;
  width: 85vw;
  height: 16.25vw;
  margin: 20vw 0 20vw 15vw;
}

.About-tea-dropdown {
  position: relative;
  display: inline-block;
  width: 20vw;
  margin-right: 25vw;
  height: 7vw;
  overflow: visible;
  white-space: nowrap;
}
.About-tea {
  display: inline-block;
  cursor: pointer;
  transform: skew(-20deg);
  width: 20vw;
  overflow: visible;
  white-space: nowrap;

  animation: fadeIn 2s;
}
.dropdown-content-wrapper {
  position: absolute;
  top: 6vw;
  left: -2vw;
  width: 20vw;
}
.dropdown-content {
  position: absolute;
  top: 3vw;
  left: -4vw;
  border-radius: 12px;
  border: 1px solid #bebebe;
  color: #bebebe;
  white-space: normal;
  font-size: 1vw;
  line-height: 1vw;
  width: 30vw;
  padding: 20px 20px 10px 20px;
  z-index: 1;

  transition: opacity 0.3s;
  opacity: 0;
}
.dropdown-content > img {
  position: absolute;
  top: -0.6vw;
  left: 8vw;
  height: 0.6vw;
}
.About-tea-dropdown:hover .dropdown-content {
  opacity: 1;
}
/* 
.About-tea:hover .dropdown-content {
  opacity: 1;
} */

/* !----------------------------------------------------------------------------------------------- */

.About-backdrop-wrapper {
  margin-left: 15vw;
  width: 85vw;
  height: 56vw;
  overflow: hidden;
}
.About-backdrop-img {
  height: 100%;
}

/* !----------------------------------------------------------------------------------------------- */

.About-heading {
  padding: 3vw 0;
  font-size: 3vw;
}

.About-skill-fade {
  overflow: hidden;
}

.About-skill-box {
  /* display: flex;
  flex-direction: row; */
  display: inline-block;

  /* padding-left: 50vw; */

  overflow: visible;
}

.About-skill-slider {
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 10s;
}

.About-skill {
  /* flex-grow: 0; */

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 4px 12px;
  margin-right: 20px;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100%));
  }
}

.About-content {
  /* margin: 20vw; */
}

.Resume {
  font-size: 1.2em;
}
