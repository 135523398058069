.Nav {
  width: 100vw;
  /* height: 70px; */
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  z-index: 999;
}

.Nav-siddhant {
  position: fixed;

  left: 85vw;
  top: 7vw;
  color: rgb(190, 190, 190);
  font-size: 1.4vw;
  cursor: pointer;
  transform: translateX(0);
  transition: transform var(--cb) 0.5s;
}

.Nav-siddhant:hover {
  transform: translateX(-3vw);
}

.Nav-anim {
  display: inline-block;
}

.earth {
  width: 0.92%;
  transition: width 1s;
}
.earth:hover {
  width: 50%;
  transition: width 1s;
}

.Nav-black {
  color: #1e1e1e;
}

@media only screen and (max-width: 480px) and (max-height: 900px),
  (max-width: 600px) and (max-height: 1125px),
  (max-width: 800px) and (max-height: 1500px),
  (max-width: 1100px) and (max-height: 2062px) {
  .Nav {
    position: absolute;
  }

  .Nav-siddhant {
    position: absolute;
    left: 70vw;
    font-size: 3vw;
  }

  .Nav-siddhant:hover {
    transform: translateX(-3vw);
  }
}
